import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import { RenderHTML } from '@dmm/react-common-components';
import loadable from '@loadable/component';
import './styles.css';

import { generateBlogPath } from '../../../../utils/urlHelpers/blog';

import BreadCrumb from '../../../../components/BreadCrumb';
import BlogNavbar from '../BlogNavbar';
import CategorySelector from '../CategorySelector';
import { PortalConfigContext } from '../../../../config/portal';
import './styles.css';
import Meta from '../../../../components/Meta';
import ArticleSchema from '../../../../components/Schema/ArticleSchema';
import CarouselSchema from '../../../../components/Schema/CarouselSchema';
import { getCanonical, getTranslation } from '../../../../utils/seo';
import { getPortalName } from '../../../../utils/language';

import AdProvider from '../../../../components/Ads/AdProvider';
import { adsConfig } from '../BlogHome/resources/adsConfig';
import adParams from '../BlogHome/resources/adParams.json';
import { getBreakpoint, BREAKPOINTS } from '../../../../utils/commonHelper';
import { getMessages } from '../../../../tppServices/translations/messages';
import { AdDisplayer } from '../../../../components/Ads/AdDisplayer/AdDisplayer';
import { getPageAdsData } from '../../../../utils/ads/adsUtils';
import { Cookies } from 'react-cookie';

const SocialShareButtons = loadable(() => import('../SocialShareButtons'));

class BlogArticle extends PureComponent {

  state = {
    breakpoint: 'desktop'
  }

  constructor(props) {
    super(props);
    this.translationMessages = getMessages();
  }

  resizeHandler = () => {
    this.setState({
      breakpoint: getBreakpoint()
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  }

  replaceBaseUrl = (url, article) => url.replace(/{{baseUrl}}/g, article.blogUrl);
  addLazyLoadingImages = (content) => content.replace(/<img/g, '<img loading=”lazy”');


  fixContentImgUrl = (article) => {
    const content = get(article.content, 'body', '');
    return this.replaceBaseUrl(content, article);
  }

  getArticleFeaturedImageURL = (article) => {
    const featuredImageUrl = get(article.content, 'featuredImage', '');
    return this.replaceBaseUrl(featuredImageUrl, article);
  }

  getArticleURL = (article) => generateBlogPath({}, { article: article.name })

  generateCarouselItemList = (relatedArticles = {}) => {
    const { records = [] } = relatedArticles;
    const articleURLs = [];
    records.forEach(article => {
      articleURLs.push({ url: this.getArticleURL(article) });
    });
    return articleURLs;
  }

  getBreadcrumbs = (breadcrumbs, t) => {
    const messages = this.translationMessages;
    return [{
      title: breadcrumbs.root,
      link: '/'
    }, {
      title: t(messages.blogRoot),
      link: generateBlogPath({}, {})
    },
      { title: breadcrumbs.article, }];
  }


  getTranslatedPublishedOn = (date, t) => {
    const [year, month, day] = date.split('-');
    const messages = this.translationMessages;
    return `${day} ${t(messages.blog[`month${month}`])} ${year}`;
  }

  getAuthorLine = (article, t) => {
    const messages = this.translationMessages;
    return (
      <div className="author-line">
        <div className="author-avatar">
          <img alt="" src={article.authorProfilePicture} />
        </div>
        <div className="written-by">
          {`${t(messages.blog.writtenBy)}: `}
          <span>{article.authorName}</span>
          {article.dateCreated && (
            <div className="published-on">
              {`${t(messages.blog.publishedOn)} `}
              <time dateTime={article.dateCreated}>{this.getTranslatedPublishedOn(article.dateCreated, t)}</time>
            </div>
          )}
        </div>
      </div>
    );
  };

  getArticleMeta = (article, t) => {
    const messages = this.translationMessages;
    const categories = get(article, 'categories', []);
    const category = categories[0] ? categories[0] : '';
    const translatedCategory = category && messages.blog.categories[category] ?
      t(messages.blog.categories[category]) :
      null;

    return (
      <div className="article-meta">
        {this.getAuthorLine(article, t)}
        {translatedCategory ?
          <div className="category">
            {`${t(messages.blog.category)}: `}
            <span>{capitalize(translatedCategory)}</span>
          </div> :
          null
        }
      </div>
    );
  }

  getArticle = (article) => {
    let content = this.fixContentImgUrl(article);
    content = this.addLazyLoadingImages(content);

    return (
      <div className="article-content">
        <RenderHTML html={content}/>
      </div>
    );
  }

  getRelatedArticles = (relatedArticles, t) => {
    const messages = this.translationMessages;
    return relatedArticles && relatedArticles.records && relatedArticles.count ?
    (
      <div className="related-articles">
        <h3>{t(messages.blog.relatedArticles)}</h3>
        <div className="articles-container">
          {relatedArticles.records.map(
            (article, index) => {
              const categories = get(article, 'categories', []);
              const category = categories[0] ? categories[0] : '';
              const translatedCategory = category && messages.blog.categories[category] ?
                t(messages.blog.categories[category]) :
                null;

              return (
                <article className={index > 1 ? 'tablet-hidden mobile-hidden' : ''} key={index} id={article.id} >
                  <a href={this.getArticleURL(article)} >
                    <div className="image-container">
                      <img src={this.getArticleFeaturedImageURL(article)} alt="" />
                    </div>
                    {translatedCategory ?
                      <div className="category">
                        {`${t(messages.blog.category)}: `}
                        <span>{capitalize(translatedCategory)}</span>
                      </div> :
                      null
                    }
                    <p className="title">{article.title}</p>
                    <div className="excerpt">
                      <RenderHTML className="excerpt" html={article.content.excerpt}/>
                    </div>
                  </a>
                </article>
              );
            }
          )}
        </div>
      </div>
    ) :
    null;
  };

  getAuthorBio = (article, t) => (
    <div className="author-bio">
      {this.getAuthorLine(article, t)}
      {article.authorBiographicalInfo ? <p>{article.authorBiographicalInfo}</p> : null}
    </div>
  )

  getAdsColumn = (newPageAdsConfig) => (
    <>
      <AdDisplayer
        newAdsConfig={newPageAdsConfig}
        adSlot={'box-1'}
        googleAdParams={adParams.box1Params} />
      <AdDisplayer
        newAdsConfig={newPageAdsConfig}
        adSlot={'box-2'}
        googleAdParams={adParams.box2Params} />
      <AdDisplayer
        newAdsConfig={newPageAdsConfig}
        adSlot={'box-3'}
        googleAdParams={adParams.box3Params} />
    </>
  )

  render() {
    const {
      article,
      relatedArticles,
      intl: { formatMessage: t },
    } = this.props;
    const context = get(this, 'context', {});
    const pagesContext = get(context, 'pages', {});
    const blogContext = get(pagesContext, 'blog', {});
    const categories = get(blogContext, 'categories', []);
    const homeContext = get(blogContext, 'home', {});
    const breadcrumbs = {root: get(homeContext, 'breadcrumbs', ''), article: article.title};

    const articleContext = get(blogContext, 'article', {});
    const url = this.props.location.pathname;
    const pageAdsConfig = merge(articleContext.adsConfig, adsConfig);
    const portal = getPortalName(context);
    const ItemList = this.generateCarouselItemList(relatedArticles);
    const canonicalUrl = article?.seo?.canonical ? article.seo.canonical : getCanonical(url);
    const cdnUrl = context.client?.cdn?.endpoint;

    let newPageAdsConfig = getPageAdsData(blogContext.newAdsConfig.adZones, this.props.adsData, cdnUrl);

    return (
      <>
        <AdProvider url={url} targeting={{ page: 'blog-home' }} adsConfig={pageAdsConfig}>
          <Meta
            canonical={canonicalUrl}
            title={getTranslation('blog.articlePageSEO.metaElements.title', { title: article.title, portal: portal })}
            description={get(article, 'content.excerpt', undefined)}
          />
          <ArticleSchema article={article} />
          <CarouselSchema ItemList={ItemList} />
          <div className="blog-article">
            <BlogNavbar categories={categories} {...this.props}/>
            <BreadCrumb items={this.getBreadcrumbs(breadcrumbs, t)} />
            <div className="body">
              <article>
                <h1>{article.title}</h1>
                {this.getArticleMeta(article, t)}
                {this.getArticle(article)}
                <div className="share-buttons-sticky-container">
                  <div className="share-buttons-container">
                    <SocialShareButtons iconSize="30px" facebook pinterest x email more/>
                  </div>
                </div>
                {this.getAuthorBio(article, t)}
                {this.getRelatedArticles(relatedArticles, t)}
              </article>
              { this.state.breakpoint === BREAKPOINTS.desktop && <div className="ads-column" >{this.getAdsColumn(newPageAdsConfig)}</div> }
            </div>
            {
              this.state.breakpoint === BREAKPOINTS.mobile ?
                <AdDisplayer
                  newAdsConfig={newPageAdsConfig}
                  adSlot={'mobile-box-1'}
                  googleAdParams={adParams.mobileBox1Params} /> :
                <AdDisplayer
                  newAdsConfig={newPageAdsConfig}
                  adSlot={'leaderboard-bottom'}
                  googleAdParams={adParams.leaderboardBottomParams} />
            }
            <div className="category-selector-container">
              <CategorySelector categories={categories} {...this.props}/>
            </div>
          </div>
        </AdProvider>
      </>
    );
  }
}

BlogArticle.propTypes = {
  article: PropTypes.object.isRequired,
  relatedArticles: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  adsData: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies),
  abTestContext: PropTypes.object,
};

BlogArticle.contextType = PortalConfigContext;

export default connect(
  null,
  dispatch => bindActionCreators({}, dispatch)
)(injectIntl(BlogArticle));
